import { createRouter, createWebHistory } from "vue-router";
//import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    //component: Home,
    redirect: "/login",
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../pages/user/login.vue"),
  },
  {
    path: "/editor/:id",
    name: "editor",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/editor.vue"),
  },
  {
    path: "/editor2/:id",
    name: "editor2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/editor2.vue"),
  },
  {
    path: "/effect1",
    name: "effect1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/effect1.vue"),
  },
  {
    path: "/player/:id",
    name: "player",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/player.vue"),
  },
  {
    path: "/test/test1",
    name: "test1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../pages/test/test1.vue"),
  },
  {
    path: "/test/test2",
    name: "test2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../pages/test/test2.vue"),
  },
  {
    path: "/user/index",
    name: "user_index",
    component: () => import(/* webpackChunkName: "pages" */ "../pages/user/index.vue"),
    children: [
      {
        path: "/user/workplace",
        name: "user_workplace",
        component: () => import(/* webpackChunkName: "pages" */ "../pages/user/workplace.vue"),
      },
      {
        path: "/user/myproject",
        name: "user_myproject",
        component: () => import(/* webpackChunkName: "pages" */ "../pages/user/myproject.vue"),
      },
      {
        path: "/user/myresource",
        name: "user_myresource",
        component: () => import(/* webpackChunkName: "pages" */ "../pages/user/myresource.vue"),
      },
      {
        path: "/user/publibrary",
        name: "publibrary",
        component: () => import(/* webpackChunkName: "pages" */ "../pages/user/publibrary.vue"),
      },
      {
        path: "/user/wait1",
        name: "user_wait1",
        component: () => import(/* webpackChunkName: "pages" */ "../pages/user/wait1.vue"),
      },
      {
        path: "/user/wait2",
        name: "user_wait2",
        component: () => import(/* webpackChunkName: "pages" */ "../pages/user/wait2.vue"),
      },
      {
        path: "/user/wait3",
        name: "user_wait3",
        component: () => import(/* webpackChunkName: "pages" */ "../pages/user/wait3.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
