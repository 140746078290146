import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import animated from "animate.css";

//import axios from "axios";

//createApp(App).use(ElementPlus).use(store).use(router).mount("#app");

const app = createApp(App);
app.use(ElementPlus, { size: "mini", zIndex: 3000 });
app.use(store);
app.use(router);
app.use(animated);
app.mount("#app");
//app.config.globalProperties["$http"] = axios;
