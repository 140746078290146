<template>
  <router-view />
</template>

<style>
html,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

pre {
  margin: 0;
}
#app {
  font-family: "Microsoft YaHei";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

/* .animate__animated.animate__delay-1s {
  -webkit-animation-delay: calc(1s * 1);
  animation-delay: calc(1s * 1);
  -webkit-animation-delay: calc(var(--animate-delay) * 1);
  animation-delay: calc(var(--animate-delay) * 1);
}
.animate__animated.animate__delay-2s {
  -webkit-animation-delay: calc(1s * 2);
  animation-delay: calc(1s * 2);
  -webkit-animation-delay: calc(var(--animate-delay) * 2);
  animation-delay: calc(var(--animate-delay) * 2);
}
.animate__animated.animate__delay-3s {
  -webkit-animation-delay: calc(1s * 3);
  animation-delay: calc(1s * 3);
  -webkit-animation-delay: calc(var(--animate-delay) * 3);
  animation-delay: calc(var(--animate-delay) * 3);
}
.animate__animated.animate__delay-4s {
  -webkit-animation-delay: calc(1s * 4);
  animation-delay: calc(1s * 4);
  -webkit-animation-delay: calc(var(--animate-delay) * 4);
  animation-delay: calc(var(--animate-delay) * 4);
}
.animate__animated.animate__delay-5s {
  -webkit-animation-delay: calc(1s * 5);
  animation-delay: calc(1s * 5);
  -webkit-animation-delay: calc(var(--animate-delay) * 5);
  animation-delay: calc(var(--animate-delay) * 5);
}
.animate__animated.animate__delay-6s {
  -webkit-animation-delay: calc(1s * 6);
  animation-delay: calc(1s * 6);
  -webkit-animation-delay: calc(var(--animate-delay) * 6);
  animation-delay: calc(var(--animate-delay) * 6);
}
.animate__animated.animate__delay-7s {
  -webkit-animation-delay: calc(1s * 7);
  animation-delay: calc(1s * 7);
  -webkit-animation-delay: calc(var(--animate-delay) * 7);
  animation-delay: calc(var(--animate-delay) * 7);
}
.animate__animated.animate__delay-8s {
  -webkit-animation-delay: calc(1s * 8);
  animation-delay: calc(1s * 8);
  -webkit-animation-delay: calc(var(--animate-delay) * 8);
  animation-delay: calc(var(--animate-delay) * 8);
}
.animate__animated.animate__delay-9s {
  -webkit-animation-delay: calc(1s * 9);
  animation-delay: calc(1s * 9);
  -webkit-animation-delay: calc(var(--animate-delay) * 9);
  animation-delay: calc(var(--animate-delay) * 9);
}
.animate__animated.animate__delay-10s {
  -webkit-animation-delay: calc(1s * 10);
  animation-delay: calc(1s * 10);
  -webkit-animation-delay: calc(var(--animate-delay) * 10);
  animation-delay: calc(var(--animate-delay) * 10);
}

.animate__animated.animate__delay-11s {
  animation-delay: calc(1s * 11);
  animation-delay: calc(var(--animate-delay) * 11);
}
.animate__animated.animate__delay-12s {
  animation-delay: calc(1s * 12);
  animation-delay: calc(var(--animate-delay) * 12);
}
.animate__animated.animate__delay-13s {
  animation-delay: calc(1s * 13);
  animation-delay: calc(var(--animate-delay) * 13);
}
.animate__animated.animate__delay-14s {
  animation-delay: calc(1s * 14);
  animation-delay: calc(var(--animate-delay) * 14);
}
.animate__animated.animate__delay-15s {
  animation-delay: calc(1s * 15);
  animation-delay: calc(var(--animate-delay) * 15);
}
.animate__animated.animate__delay-16s {
  animation-delay: calc(1s * 16);
  animation-delay: calc(var(--animate-delay) * 16);
}
.animate__animated.animate__delay-17s {
  animation-delay: calc(1s * 17);
  animation-delay: calc(var(--animate-delay) * 17);
}
.animate__animated.animate__delay-18s {
  animation-delay: calc(1s * 18);
  animation-delay: calc(var(--animate-delay) * 18);
}
.animate__animated.animate__delay-19s {
  animation-delay: calc(1s * 19);
  animation-delay: calc(var(--animate-delay) * 19);
}
.animate__animated.animate__delay-20s {
  animation-delay: calc(1s * 20);
  animation-delay: calc(var(--animate-delay) * 20);
} */

.title1 > div {
  font-size: 28px;
  color: #fff;
  background-color: rgba(29, 154, 120, 0.7);
  margin-bottom: 15px;
  border-radius: 10px;
  padding: 15px;
  line-height: 200%;
  font-family: "Microsoft YaHei";
  box-shadow: 0px 2px 27px 6px rgba(204, 204, 204, 1);
}

.list1 {
  font-size: 22px;
  color: #2b2b2b;
}
.list1 :first-child {
  color: #fff;
  background-color: rgba(29, 154, 120, 0.7);
  margin-bottom: 15px;
  border-radius: 10px;
  padding: 15px;
  line-height: 150%;
  font-family: "Microsoft YaHei";
  box-shadow: 0px 2px 7px 6px rgba(204, 204, 204, 1);
}
.list1 :not(:first-child) {
  color: #666;
  background-color: rgba(255, 255, 255, 0.8);
  margin-bottom: 15px;
  border-radius: 10px;
  padding: 15px;
  line-height: 150%;
  font-family: "黑体";
  box-shadow: 0px 2px 7px 6px rgba(204, 204, 204, 1);
}

.list2 > div {
  font-size: 22px;
  color: rgba(29, 154, 120, 0.7);
  background-color: rgba(255, 255, 255, 0.8);
  margin-bottom: 15px;
  border-radius: 10px;
  padding: 15px;
  line-height: 150%;
  font-family: "Microsoft YaHei";
  box-shadow: 0px 2px 7px 6px rgba(204, 204, 204, 1);
}

.content1 {
  padding: 20px;
  font-size: 22px;
  color: #999;
  margin-bottom: 15px;
  border-radius: 20px;
  padding: 15px;
  line-height: 200%;
  font-family: "Microsoft YaHei";
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 27px 6px rgba(204, 204, 204, 1);
}

/*控制整个滚动条*/
::-webkit-scrollbar {
  background-color: #f0f0f0;
  width: 10px;
  height: 10px;
  background-clip: padding-box;
}

/*滚动条两端方向按钮*/
::-webkit-scrollbar-button {
  background-color: #e0e0e0;
}

/*滚动条中间滑动部分*/
::-webkit-scrollbar-thumb {
  background-color: #d0d0d0;
  border-radius: 5px;
}

/*滚动条右下角区域*/
::-webkit-scrollbar-corner {
  background-color: #e0e0e0;
}

@font-face {
  font-family: "站酷酷黑体";
  src: url("/fonts/zcool151105.ttf");
}
</style>
