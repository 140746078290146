import { createStore } from "vuex";

export default createStore({
  state: {
    msg: "vuex msg",
    editor: {
      id: 111,
      text: "sdadasd",
      project: {},
      meta: {},
      currentSenceId: "",
      currentSpriteId: "",
      currentSence: {},
      currentSprite: {},
      copySprite: "",
      deleteItem: [],
      bgList: [],
    },
  },
  mutations: {},
  actions: {},
  modules: {},
});
